
import { useEffect, useState } from 'react';
import { appConfig } from '../appConfig';
import './ImageViewer.scss';
import { IFile } from '../types';

interface ImageViewerProps {
  file: IFile;
}

export default function ImageViewer({ file }: ImageViewerProps) {
  const [src, setSrc] = useState('blank');

  useEffect(()=> {
    setSrc('blank');
    if (file.name) {
      const final = new Image();
      final.src = appConfig.mediaUrl + 'f-' + file.name + '.webp';
      if (final.complete) {
        setSrc(final.src);
      } else {
        const thumb = new Image();
        thumb.src = appConfig.mediaUrl + 't-' + file.name + '.webp';
        if (thumb.complete) {
          setSrc(thumb.src);
          final.onload = () => {
            setSrc(final.src);
          }
        } else {
          thumb.onload = () => {
            setSrc(thumb.src);
            if (final.complete) {
              setSrc(final.src);
            } else {
              final.onload = () => {
                setSrc(final.src);
              }
            }
          }
        }
      }
    }
  }, [file.name]);

  return (
    <img className='image-viewer' src={src} alt='Bounty Image' width={file.width} height={file.height}></img>
  );
}
import { useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { updateName } from '../../api';
import { useAppContext } from '../../contexts/AppContext';
import { useUserContext } from '../../contexts/UserContext';

interface NameEditProps {
  next?: string;
}

export default function NameEdit({ next }: NameEditProps) {
  const navigate = useNavigate();
  const { snackSuccess } = useAppContext();
  const { user, setUser } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [pageError, setPageError] = useState('');

  useEffect(() => {
    setFirstName(user.firstName);
    setLastName(user.lastName);
  }, [user]);

  async function applyClicked() {
    try {
      setPageError('');
      setLoading(true);
  
      if (!firstName.trim()) {
        setPageError("Please enter your first name. This field cannot be left blank.");
      } else if (!lastName.trim()) {
        setPageError("Please enter your last name. This field cannot be left blank.");
      } else {
        const result = await updateName(firstName, lastName);
        if (result.status !== 200) {
          setPageError("We encountered an issue while processing your information (Error: " + result.status + "). Please verify your details are correct and try again, or contact our support team for assistance.");
        } else {
          setUser(result.data);
          if (next) {
            navigate(next);
          } else {
            snackSuccess("Success! Your legal name has been updated.");
            navigate('/account');
          }
        }
      }
    }
    catch (error) {
      setPageError("A system error occurred. We apologize for the inconvenience. Please try again later, or if the issue persists, contact our support team for further assistance. Error Details: " + error);
    }
    finally {
      setLoading(false);
    }
  }
  
  return (
    <div className='form-page'>
    <h1>Legal Name Verification</h1>
    <h2>Required for Tax Documentation</h2>
    <p>
      To comply with tax regulations, we require your full legal name. This information will be used solely for official tax documentation and reporting purposes.
    </p>
    <TextField
      label="First Name"
      variant="outlined"
      value={firstName}
      helperText="Enter your first name as it appears on official documents."
      onChange={event => {
        setFirstName(event.target.value.substring(0, 30));
      }}
    />
    <TextField
      label="Last Name"
      variant="outlined"
      value={lastName}
      helperText="Enter your last name as it appears on official documents."
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          applyClicked();
        }
      }}
      onChange={event => {
        setLastName(event.target.value.substring(0, 30));
      }}
    />
    {pageError && <Alert severity="error">{pageError}</Alert> }
    <LoadingButton 
      variant="contained" 
      loading={loading} 
      onClick={applyClicked}>
      <span>{"Submit Legal Name"}</span>
    </LoadingButton>
  </div>  
  );
};

import "./FormLayout.scss";
import { Outlet } from 'react-router-dom';
import PageFooter from '../PageFooter';
import PageHeader from '../PageHeader';
import { ProtectedRoute } from '../ProdtectedRoute';

interface FormLayoutProps {
  showLogin?: boolean;
  role: number;
}

export default function FormLayout({ showLogin, role }: FormLayoutProps) {
  return (
    <ProtectedRoute role={role}>
      <div className="main-body">
        <PageHeader showLogin={showLogin} />
        <Outlet />
        <PageFooter />
      </div>
    </ProtectedRoute>
  );
};

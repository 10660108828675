import './BountyBar.scss';
import { Bounty } from '../../types';
import { Link, useNavigate } from 'react-router-dom';
import { bountyActive, formatCountdown, formatMoney } from '../../globals';
import { useEffect, useState } from 'react';

interface BountyBarProps {
  bounty: Bounty;
}

export default function BountyBar({ bounty }: BountyBarProps) {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState('');
  const [active, setActive] = useState(0);

  useEffect(() => {
    const updateState = () => {
      if (!bounty) {
        setActive(0);
        setCountdown('');
      } else {
        setActive(bountyActive(bounty));
        setCountdown(formatCountdown(bounty.endDate, true));
      }
    };
    updateState();
    const timerId = setInterval(updateState, 5000);
    return () => clearInterval(timerId);
  }, [bounty]); 

  function bountyClicked() {
    navigate(`/bounty/${bounty._id}`);
  }

  if (!bounty) {
    return <div />;
  }

  return (
    <div className='bounty-bar' onClick={bountyClicked}>
      <div className='title'>{bounty.title}</div>
      <div className='details'>
        <div className='countdown'>
          {
            active === 1 ? 'Fund This New Bounty!'
            : active === 2 ? `Active: ${countdown} remaining`
            : `Finalized: ${countdown} ago`
          }
        </div>
        <div className='amount'>Bounty: {formatMoney(bounty.silver)}</div>
      </div>
    </div>
  );
};

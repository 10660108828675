import './IdentityVerify.scss';
import { useState } from 'react';
import { Alert, LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { updateIdentity } from '../../api';
import { IFile } from '../../types';
import ImageUploader from '../ImageUploader';
import ImageViewer from '../ImageViewer';

interface IdentityVerifyProps {
  next?: string;
}

export default function IdentityVerify({ next }: IdentityVerifyProps) {
  const navigate = useNavigate();
  const { snackSuccess } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [idFront, setIdFront] = useState({} as IFile);
  const [idSelfie, setIdSelfie] = useState({} as IFile);
  const [pageError, setPageError] = useState('');

  async function onHandleUploadIdFront(status: number, file: IFile) {
    if (status !== 200) {
      setPageError('Error uploading identification front')
    } else {
      setIdFront(file);
    }
  }

  async function onHandleUploadIdSelfie(status: number, file: IFile) {
    if (status !== 200) {
      setPageError('Error uploading identification selfie')
    } else {
      setIdSelfie(file);
    }
  }

  async function applyClicked() {
    try {
      setPageError('');
      setLoading(true);
  
      if (!idFront) {
        setPageError("ID photo is required. Please upload a clear picture of your government-issued ID.");
      } else if (!idSelfie) {
        setPageError("Selfie with ID is required. Please upload a selfie showing you holding your ID next to your face.");
      } else {
        const result = await updateIdentity(idFront, idSelfie);
        if (result.status !== 204) {
          setPageError("We encountered an issue while updating your identity information. Error Code: " + result.status + ". Please try again or contact support if the issue persists.");
        } else {
          if (next) {
            navigate(next);
          } else {
            snackSuccess("Thank you for submitting your documents. We've successfully received them and will start the verification process shortly. We appreciate your patience and will notify you as soon as the review is complete.");
            navigate('/account');
          }
        }
      }
    }
    catch (error) {
      setPageError("An unexpected error occurred. Please try again or contact our support team if the problem continues. Error Details: " + error);
    }
    finally {
      setLoading(false);
    }
  }  

  return (
    <div className='form-page'>
      <h1>Identity Verification</h1>
      <h2>Official Document Upload</h2>
      <p>Please upload a clear, legible photo of your government-issued ID or passport. Ensure all details are visible.</p>
      <ImageViewer file={idFront} />
      <ImageUploader onUpload={onHandleUploadIdFront}>Upload Photo</ImageUploader>
      <h2>Selfie Verification</h2>
      <p>Upload a selfie where you are clearly holding your government-issued ID or passport next to your face. This step is crucial for confirming the authenticity of your identity.</p>
      <ImageViewer file={idSelfie} />
      <ImageUploader onUpload={onHandleUploadIdSelfie}>Upload Selfie</ImageUploader>
      <p className="consent-text">By submitting this form, you hereby confirm that you are at least 18 years of age and consent to the verification process in accordance with our terms of service and privacy policy.</p>
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={applyClicked}>
        <span>{'Submit for Verification'}</span>
      </LoadingButton>
    </div>
  );
};

import './PageLoading.scss';
import { MutatingDots } from 'react-loader-spinner';


export default function PageLoading() {
  return (
    <div className='page-loading'>
      <MutatingDots
        visible={true}
        height="100"
        width="100"
        color="#d83e86"
        secondaryColor="#d83e86"
        radius="15"
        ariaLabel="mutating-dots-loading"
        />
    </div>
  );
}

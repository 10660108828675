import { useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { updatePassword } from '../../api';
import useAutofillDetector from '../../hooks/useAutofillDetector';
import { isValidPassword } from '../../globals';
import { useAppContext } from '../../contexts/AppContext';

export default function PasswordEdit() {
  const navigate = useNavigate();
  const { snackSuccess } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [oldPasswordAutofilled, setOldPasswordAutofilled] = useAutofillDetector();
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordError, setNewPasswordError] = useState(false);
  const [newPasswordAutofilled, setNewPasswordAutofilled] = useAutofillDetector();

  async function applyClicked() {
    try {
      setPageError('');
      setNewPasswordError(false);
      setLoading(true);
  
      if (!oldPassword.trim()) {
        setPageError("Please enter your current password.");
      } else if (!isValidPassword(newPassword)) {
        setPageError("Your new password does not meet the required criteria. Ensure it includes a mix of uppercase, lowercase, and special characters, and is at least 8 characters long.");
        setNewPasswordError(true);
      } else {
        const result = await updatePassword(oldPassword, newPassword);
        if (result.status === 400) {
          setPageError("The current password you entered does not match our records. Please try again.");
        }
        else if (result.status !== 204) {
          setPageError("There was a problem updating your password (Error: " + result.status + "). Please ensure your details are correct and try again, or contact our support team if the issue persists.");
        } else {
          snackSuccess("Success! Your password has been changed.");
          navigate('/account');
        }
      }
    }
    catch (error) {
      setPageError("A system error occurred while updating your password. Please try again later. If the issue continues, reach out to our support team for assistance. Error Details: " + error);
    }
    finally {
      setLoading(false);
    }
  }
  
  return (
    <form noValidate>
      <div className='form-page'>
        <h1>Secure Your Account</h1>
        <h2>Change Your Password</h2>
        <p>For enhanced security, regularly update your password. Please enter your current password, followed by your new password. Choose a strong, unique password to protect your account.</p>
        <TextField
          autoComplete='off'
          inputProps={{ onAnimationStart: setOldPasswordAutofilled }}
          InputLabelProps={{ shrink: oldPassword.length > 0 || oldPasswordAutofilled }}
          type='password'
          label='Current Password'
          helperText='Enter your existing password'
          value={oldPassword}
            onChange={event => {
            setOldPassword(event.target.value);
          }}
        />
        <TextField
          autoComplete='off'
          inputProps={{ onAnimationStart: setNewPasswordAutofilled }}
          InputLabelProps={{ shrink: newPassword.length > 0 || newPasswordAutofilled }}
          type='password'
          label='New Password'
          error={newPasswordError}
          helperText='8 characters, including uppercase and lowercase letters, and at least one special character'
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              applyClicked();
            }
          }}
          value={newPassword}
            onChange={event => {
            setNewPassword(event.target.value);
          }}
        />
        {pageError && <Alert severity="error">{pageError}</Alert> }
        <LoadingButton 
          variant="contained" 
          loading={loading} 
          onClick={applyClicked}>
          <span>{"Change Password"}</span>
        </LoadingButton>
      </div> 
    </form> 
  );
};

import './PageFooter.scss';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faReddit } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faTiktok } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PageFooter() {
  return (
    <footer>
      <div className="footer-questions">
        Questions? <a href="mailto:support@bounty.fan">support@bounty.fan</a>
      </div>
      <div className="footer-links">
        <a href="https://bounty.fan">ABOUT</a>
        <a href="https://bounty.fan/signup">JOIN</a>
        <a href="https://bounty.fan/signup">PRIVACY</a>
        <a href="https://bounty.fan/signup">CONTACT</a>
        <a href="https://bounty.fan/faq">FAQ</a>
      </div>
      <div className='footer-socials'>
        <a href="https://google.com"><FontAwesomeIcon icon={faDiscord} /></a>
        <a href="https://google.com"><FontAwesomeIcon icon={faReddit} /></a>
        <a href="https://google.com"><FontAwesomeIcon icon={faTwitter} /></a>
        <a href="https://google.com"><FontAwesomeIcon icon={faInstagram} /></a>
        <a href="https://google.com"><FontAwesomeIcon icon={faTiktok} /></a>
      </div>
      <div className="footer-copyright">
        &copy; 2023 Bounty. All Rights Reserved.
      </div>
    </footer>
  );
}

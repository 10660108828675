import './Hunters.scss';

export default function Hunters() {

  return (
    <div>
      Hunters
    </div>
  );
};

import "./Account.scss";
import { User } from "../../types";
import { logout } from "../../api";
import Setting from "../Setting";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";
import PageFooter from "../PageFooter";
import PageHeader from "../PageHeader";

export default function Account() {
  const navigate = useNavigate();
  const { user, hunter, logoutUser } = useUserContext();

  async function logoutClicked() {
    await logoutUser();
    navigate('/');
  }

  function patronSettings() {
    return (
      <>
        <Setting title="Handle" path="/account/handle">
          { user.handle ? user.handle : <span className="info">Required</span> }
        </Setting>
        <Setting title="Silver" path="/account/tokens">{user.silver}</Setting>
        <Setting title="Credit Card" path="/account/payment">
          { user.creditCardLastFour ? <span>Ending in { user.creditCardLastFour }</span> : <span className="info">Setup</span> }
        </Setting>
      </>
    );
  }

  function hunterSettings() {
    return (
      <>
        <h2>Public Profile</h2>
        <Setting title="Handle" path="/account/handle">
          { user.handle ? user.handle : <span className="info">Required</span> }
        </Setting>
        <Setting title="Stage Name" path="/account/stagename">{ hunter.stagename }</Setting>
        <Setting title="Biography" path="/account/bio" />
        <Setting title="Picture" path="/account/picture" />
        <h2>Tax Information</h2>
        <Setting title="Legal Name" path="/account/name">
          { user.firstName ? user.firstName + " " + user.lastName : <span className="info">Required</span> }
        </Setting>
        <Setting title="Address" path="/account/address">
          { user.taxAddress?.street ? user.taxAddress.street : <span className="info">Required</span> }
        </Setting>
        <Setting title="SSN" path="/account/ssn">
          { user.ssnLastFour ? <span>xxx-xx-{ user.ssnLastFour }</span> : <span className="info">Required</span> }
        </Setting>
        <h2>Banking Information</h2>
        <Setting title="Direct Deposit" path="/account/bank">
          { user.bankAccountLastFour ? <span>Ending in { user.bankAccountLastFour }</span> : <span className="info">Required</span> }
        </Setting>
      </>
    );
  }

  return (
    <div className='main-body'>
      <PageHeader showLogin />
        <div className="account">
          <h1>Settings</h1>
          <h2>My Account</h2>
          <Setting title="Email" path="/account/email">{user.emailNew || user.email}</Setting>
          <Setting title="Password" path="/account/password" />
          { user.role === 2 ? hunterSettings() : patronSettings() }
          <h2>Account Actions</h2>
          <Setting title="Logout" onClick={logoutClicked} />
        </div>
      <PageFooter />
    </div>
  );
}

import { useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Navigate, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import { updateHunterHandle } from '../../api';

export default function HunterHandle() {
  const navigate = useNavigate();
  const { user, hunter, setHunter } = useUserContext();
  const [pageError, setPageError] = useState('');
  const [loading, setLoading] = useState(false);
  const [handle, setHandle] = useState('');

  useEffect(() => {
    setHandle(hunter.handle ?? '');
  },[hunter]);

  function handleChanged(event: any) {
    let newHandle = event.target.value;
    newHandle = newHandle.replace(/[^a-zA-Z0-9]/g, ''); 
    setHandle(newHandle.substring(0,15));
  }

  async function applyClicked() {
    try {
      setPageError('');
      setLoading(true);
  
      if (!handle.trim()) {
        setPageError("Please enter a user handle before proceeding.");
      } else {
        const result = await updateHunterHandle(handle);
        if (result.status === 409) {
          setPageError('The chosen handle is already in use. Please select a different handle.');
        } else if (result.status !== 200) {
          setPageError('We encountered a problem while setting your handle (Error: ' + result.status + '). Please try again, or contact support if the issue persists.');
        } else {
          setHunter(result.data);
          navigate('/enrollment/stagename');
        }
      }
    }
    catch (error) {
      setPageError('A system error occurred. Please try again later, or if the problem continues, reach out to our support team. Error Details: ' + error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='form-page'>
      <h3>Step 2 of 3</h3>
      <h1>Create Your Public Handle</h1>
      <h2>Stand Out on Bounty</h2>
      <p>Your handle is your unique identity on Bounty and how others will recognize and interact with you. Choose a handle that reflects your persona or brand. Keep it memorable and distinct to ensure you stand out in the community.</p>
      <TextField
        label='Handle'
        helperText="Choose a handle between 3-15 characters. Avoid special characters."
        variant='outlined'
        value={handle}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            applyClicked();
          }
        }}
        onChange={handleChanged}
      />
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={applyClicked}>
        <span>{'Next'}</span>
      </LoadingButton>
    </div>
  );
};

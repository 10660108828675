import { IFile } from "../types";
import ImageViewer from "./ImageViewer";

interface MediaViewerProps {
  file: IFile;
}

export default function MediaViewer({ file }: MediaViewerProps) {
  if (!file?.media) return null;
  switch(file.media) {
    default:
      return <ImageViewer file={file} />
  }
}
import './Register.scss';
import { useState } from 'react';
import { Alert, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useAutofillDetector from '../../hooks/useAutofillDetector';
import { isValidEmail, isValidPassword } from '../../globals';
import { authenticate, createUser } from '../../api';
import { useUserContext } from '../../contexts/UserContext';
import PageFooter from '../PageFooter';
import PageHeader from '../PageHeader';

export default function Register() {
  const navigate = useNavigate();
  const { setUser } = useUserContext();
  const { role } = useParams();
  const [loading, setLoading] = useState(false);
  const [signUpError, setSignUpError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailAutofilled, setEmailAutofilled] = useAutofillDetector();
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordAutofilled, setPasswordAutofilled] = useAutofillDetector();
  const [userType, setUserType] = useState(role === 'creator' ? 1 : 0);

  async function createAccountClicked() {
    try {
      setLoading(true);

      if (!isValidEmail(email)) {
        setEmailError(true);
        setSignUpError('Please enter a valid email address.')
        return;
      }

      if (!isValidPassword(newPassword)) {
        setPasswordError(true);
        setSignUpError('Please enter a valid password.')
        return;
      }

      const createUserResult = await createUser(email, newPassword, userType === 1);
      if (createUserResult.status === 406) {
        setSignUpError("Email already exists. Please sign in.");
      } else if (createUserResult.status !== 200) { 
        setSignUpError("Error createAccount(" + createUserResult.status + ")");
      } else {
        const authenticateResult = await authenticate(email, newPassword);
        if (authenticateResult.status !== 200) {
          setSignUpError("Error authenticate(" + authenticateResult.status + ")");
        } else {
          localStorage.setItem('ss', authenticateResult.data._id);
          setUser(createUserResult.data); 
          navigate(userType === 1 ? '/enrollment/handle' : '/signup/plan');
        }
      }
    }
    catch (error) {
      setSignUpError("Error " + error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='main-body'>
      <PageHeader showLogin={true} />
      <div className='form-page'>
        <h3>Step 1 of { userType === 1 ? '4' : '5' }</h3>
        <h1>Enter your email and password to start your { userType === 1 ? "journey" : "membership" }</h1>
        {
          userType === 1 ? 
          <p>
            <strong>Empower Your Creativity and Boost Your Visibility with Bounty!</strong> As a content creator on Bounty, you not only own your content but also get the thrilling opportunity to participate in exciting bounties. Engage in creative challenges, showcase your talents, and compete for winnings. Bounties are more than just contests; they're your gateway to fame and recognition. Join us, let your creativity shine, and be the star you're meant to be!
          </p>
          : 
          <p>
            <strong>Step into a World Where Your Fantasies Shape Reality!</strong> As a member of Bounty, you're the master of creativity, setting the stage for content creators to bring your fantasies to life. Post your dream scenarios in our bounties and watch as top talents compete to fulfill them. Your vote decides who triumphs, turning your vision into a vibrant reality. Join Bounty today and be part of an exhilarating journey where you're in control, and your imagination is the limit!
          </p>
        }
        <div>
          <RadioGroup row value={userType} onChange={(event) => {
            setUserType(Number(event.target.value))
          }}>
            <FormControlLabel value="0" control={<Radio />} label="Member" />
            <FormControlLabel value="1" control={<Radio />} label="Content Creator" />
          </RadioGroup>
        </div>
        <TextField 
          autoComplete='off'
          inputProps={{ onAnimationStart: setEmailAutofilled }}
          InputLabelProps={{ shrink: email.length > 0 || emailAutofilled }}
          label="Email"
          value={email}
          error={emailError}
          helperText={emailError && 'Inavlid Email'}
          onChange={(event) => {
            setSignUpError('');
            setEmailError(false);
            setEmail(event.target.value);
          }}
        />
        <TextField
          autoComplete='off'
          inputProps={{ onAnimationStart: setPasswordAutofilled }}
          InputLabelProps={{ shrink: newPassword.length > 0 || passwordAutofilled }}
          type='password'
          label='Add a password'
          error={passwordError}
          helperText='8 characters, including uppercase and lowercase letters, and at least one special character'
          value={newPassword}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              createAccountClicked();
            }
          }}
          onChange={(event) => {
            setSignUpError('');
            setPasswordError(false);
            setNewPassword(event.target.value);
          }}
        />
        {signUpError && (
          <Alert severity="error">{signUpError}</Alert>
        )}
        <LoadingButton 
          variant="contained" 
          loading={loading} 
          onClick={createAccountClicked}>
          <span>Next</span>
        </LoadingButton>
        <div className='login-message'>
          <p>Already have an account? <Link to='/login'>Sign in here</Link></p>
        </div>
      </div>
      <PageFooter />
    </div>
  );
};

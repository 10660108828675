import { useState, useCallback } from 'react';

export default function useAutofillDetector() : [boolean, ((e: any) => void)] {
  const [isAutofilled, setIsAutofilled] = useState(false);

  const onAutofillStart = useCallback((e: any) => {
    if (e.animationName === "mui-auto-fill" || e.animationName === "mui-auto-fill-cancel") {
      setIsAutofilled(!!e.target?.matches("*:-webkit-autofill"));
    }
  }, []);

  return [isAutofilled, onAutofillStart];
};

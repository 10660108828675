import { useState } from 'react';
import { Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Navigate, useNavigate } from 'react-router-dom';
import CreditCardForm from '../account/CreditCardForm';
import { ICreditCard } from '../../types';
import { startMembership } from '../../api';
import { useUserContext } from '../../contexts/UserContext';

export default function Payment() {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState('');
  const [cardInfo, setCardInfo] = useState({} as ICreditCard);

  async function applyClicked() {
    try {
      setPageError('');
      const result = await startMembership(cardInfo);
      if (result.status !== 200) {
        setPageError("We encountered an issue while updating your credit card information. Error: " + result.status);
      } else {
        setUser(result.data)
        navigate('/');
      }
      setLoading(true);
    }
    catch (error) {
      setPageError("A system error occurred while updating your credit card information. Please try again later, and if the issue persists, contact our support team for assistance. Error: " + error);
    }
    finally {
      setLoading(false);
    }
  }

  if (user.role !== 1 || new Date(user.active) > new Date()) {
    return <Navigate to="/join" replace />;
  }

  return (
    <div className='form-page'>
      <h3>Step 4 of 4</h3>
      <h1>Your almost done! Set up your credit or debit card</h1>
      <CreditCardForm onCardChanged={setCardInfo} />
      <p>By selecting the "Start Membership" button below, you acknowledge and agree to the <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>Terms of Use and Privacy Statement</a>. You also confirm that you are at least 18 years old. Please be aware that your Bounty membership will automatically renew, and you authorize us to charge the membership fee of $4.99 per month to your designated payment method. You may cancel your membership at any time to prevent future charges by navigating to your Account settings and selecting "Cancel Membership."</p>
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <LoadingButton
        variant="contained"
        loading={loading}
        onClick={applyClicked}>
        <span>{'Start Membership'}</span>
      </LoadingButton>
    </div>
  );
};

import React, { act } from 'react';
import { Navigate } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext';
import PageLoading from './PageLoading';

interface ProtectedRouteProps {
  children: React.ReactNode;
  role: number;
  active?: boolean;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, role, active }) => {
  const { user, hunter } = useUserContext();

  if (user.unresolved) {
    return <PageLoading />
  }

  if (!(user.role & role)) {
    return <Navigate to="/join" replace />;
  }

  if (active) {
    if (user.role === 1 && new Date(user.active) < new Date()) {
      return <Navigate to="/join" replace />;
    }
  
    if (user.role === 2 && !hunter.handle) {
      return <Navigate to="/join" replace />;
    }
  }
  return <>{children}</>;
};
import './ClaimActiveBar.scss';
import { faCoins } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from 'react';
import { getVote, postVote } from '../../api';
import { Claim, Vote } from '../../types';
import { Button, ButtonGroup } from '@mui/material';


interface ClaimActiveBarProps {
  claim: Claim;
  onVote: () => void;
}

export default function ClaimActiveBar({ claim, onVote }: ClaimActiveBarProps) {
  const [loading, setLoading] = useState(false);
  const [vote, setVote] = useState({ unresolved: true } as Vote);

  useEffect(()=> {
    if (claim._id) {
      getVote(claim._id).then(result => {
        setVote(result.status !== 200 ? {} as Vote : result.data);
      });
    }
  }, [claim]);

  async function voteClicked(amount: number) {
    try {
      setLoading(true);
      const result = await postVote(claim._id, claim.bountyId, amount);
      if (result.status !== 200) {
        console.error('Post Vote Error: ' + result.status);
      } else {
        setVote(result.data);
        onVote();
      }
    }
    catch (error) {
      console.error("Vote Clicked Error: ", error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <div className='claim-active-bar'>
      {
        [
          { silver: 1, votes: '+1 vote' },
          { silver: 5, votes: '+2 votes' },
          { silver: 10, votes: '+3 votes' },
          { silver: 25, votes: '+4 votes' },
          { silver: 50, votes: '+5 votes' }
        ].map(value => (
          <Button
            fullWidth
            variant='contained'
            disabled={loading || vote.unresolved || vote.votes > 0}
            onClick={()=>voteClicked(value.silver)}
            sx={ value.silver !== vote.silver ? {
            } : {
              '&.Mui-disabled': {
                backgroundColor: 'green',
                color: 'white',
              },
            }}
          >
            <div className='fund-button'>
              <div className='amount'>
                <FontAwesomeIcon className='icon' icon={faCoins} />
                <span className='text'>{value.silver}</span>
              </div>
              <span className='votes'>{value.votes}</span>
            </div>
          </Button>
        ))
      }
    </div>
  )
}
import './BountyGrid.scss';
import { Bounty } from '../../types';
import ClaimThumb from './ClaimThumb';

interface BountyGridProps {
  bounty: Bounty;
}

export default function BountyGrid({ bounty }: BountyGridProps) {
  return (
    <div className="bounty-grid" key={bounty._id}>
    {
      bounty.claims.map(claim => <ClaimThumb key={claim._id} claim={claim} />)
    }
    </div>
  );
}

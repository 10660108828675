import Home from "./components/Home";
import Login from "./components/account/Login";
import { BrowserRouter as Router, Route, Routes, createRoutesFromElements, createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import Hunters from "./components/Hunters";
import MainLayout from "./components/layouts/MainLayout";
import { useState } from "react";
import BountyPage from "./components/bounties/BountyPage";
import BountyCreate from "./components/bounties/BountyCreate";
import HunterPage from "./components/HunterPage";
import FormLayout from "./components/layouts/FormLayout";
import { Alert, Snackbar } from "@mui/material";
import { AlertColor } from "@mui/material/Alert";
import HunterPost from "./components/HunterPost";
import Account from "./components/account/Account";
import HandleEdit from "./components/account/HandleEdit";
import Plan from "./components/signup/Plan";
import BankEdit from "./components/account/BankEdit";
import IdentityVerify from "./components/account/IdentityVerify";
import SsnEdit from "./components/account/SsnEdit";
import NameEdit from "./components/account/NameEdit";
import AddressEdit from "./components/account/AddressEdit";
import PaymentEdit from "./components/account/PaymentEdit";
import PasswordEdit from "./components/account/PasswordEdit";
import EmailEdit from "./components/account/EmailEdit";
import PasswordForgot from "./components/account/PasswordForgot";
import PasswordReset from "./components/account/PasswordReset";
import ConfettiExplosion from "react-confetti-explosion";
import ProfilePicture from "./components/account/ProfilePicture";
import BioEdit from "./components/account/BioEdit";
import AppContext from "./contexts/AppContext";
import ClaimPage from "./components/bounties/ClaimPage";
import BountyPost from "./components/PostCreate";
import { UserProvider } from "./contexts/UserContext";
import Join from "./components/signup/Join";
import Register from "./components/signup/Register";
import Payment from "./components/signup/Payment";
import PatronHandle from "./components/signup/PatronHandle";
import HunterHandle from "./components/signup/HunterHandle";
import SignUpStageName from "./components/signup/StageName";

window.history.scrollRestoration = 'manual';

function App() {
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackSeverity, setSnackSeverity] = useState<AlertColor>("error");
  const [snackText, setSnackText] = useState("");
  const [snackKey, setSnackKey] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false);

  function snackClose() {
    setSnackOpen(false);
  }

  function snackError(text: string) {
    if (text) {
      setSnackText(text);
      setSnackSeverity("error");
      setSnackOpen(true);
      setSnackKey((prev) => prev + 1);
    }
  }

  function snackSuccess(text: string) {
    if (text) {
      setSnackText(text);
      setSnackSeverity("success");
      setSnackOpen(true);
      setSnackKey((prev) => prev + 1);
    }
  }

  function fireConfetti() {
    setShowConfetti(true);
    setTimeout(() => {
      setShowConfetti(false);
    }, 5000);
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="/join" element={<Join />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register/:role" element={<Register />} />
        <Route path="/forgot" element={<PasswordForgot />} />
        <Route path="/reset/:id" element={<PasswordReset />} />
        <Route path="/account" element={<Account />} />
        <Route path="/signup" element={<FormLayout showLogin role={1} />}>
          <Route path="plan" element={<Plan />} />
          <Route path="handle" element={<PatronHandle />} />
          <Route path="payment" element={<Payment />} />
        </Route>
        <Route path="/enrollment" element={<FormLayout showLogin role={2} />}>
          <Route path="handle" element={<HunterHandle />} />
          <Route path="stagename" element={<SignUpStageName />} />
        </Route>
        <Route path="/account" element={<FormLayout role={3} />}>
          <Route path="email" element={<EmailEdit />} />
          <Route path="password" element={<PasswordEdit />} />
          <Route path="handle" element={<HandleEdit />} />
          <Route path="bio" element={<BioEdit />} />
          <Route path="picture" element={<ProfilePicture />} />
          <Route path="payment" element={<PaymentEdit />} />
          <Route path="name" element={<NameEdit />} />
          <Route path="address" element={<AddressEdit />} />
          <Route path="ssn" element={<SsnEdit />} />
          <Route path="identity" element={<IdentityVerify />} />
          <Route path="bank" element={<BankEdit />} />
        </Route>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="/bounty/create" element={<BountyCreate />} />
          <Route path="/bounty/post/:id" element={<BountyPost />} />
          <Route path="/bounty/:id" element={<BountyPage />} />
          <Route path="/claim/:id" element={<ClaimPage />} />
          <Route path="/profile/:id" element={<HunterPage />} />
          <Route path="/hunters/" element={<Hunters />} />
          <Route path="/profile/" element={<HunterPage />} />
          <Route path="/profile/post" element={<HunterPost />} />
        </Route>
      </Route>
    )
  );

  return (
    <AppContext.Provider value={{ snackError, snackSuccess, snackClose, fireConfetti }}>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
          <Snackbar
            key={snackKey}
            open={snackOpen}
            autoHideDuration={6000}
            onClose={snackClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <Alert
              onClose={snackClose}
              severity={snackSeverity}
              sx={{ width: "100%", fontSize: 18 }}
            >
              {snackText}
            </Alert>
          </Snackbar>
          { showConfetti && <ConfettiExplosion /> }
        </ThemeProvider>
      </UserProvider>
    </AppContext.Provider>
  );
}

export default App;

import { createContext, useContext } from 'react';

export interface AppContextType {
  snackError: (text: string) => void;
  snackSuccess: (text: string) => void;
  snackClose: () => void;
  fireConfetti: () => void;
}

export function useAppContext(): AppContextType {
  const context = useContext(SnackContext);

  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider');
  }

  return context;
}

const SnackContext = createContext<AppContextType | null>(null);
export default SnackContext;


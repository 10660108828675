import './PageHeader.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { useUserContext } from '../contexts/UserContext';

interface PageHeaderProps {
  showLogin?: boolean;
}

export default function PageHeader({ showLogin }: PageHeaderProps) {
  const navigate = useNavigate();
  const { user, logoutUser } = useUserContext();

  function loginClicked() {
    navigate("/login");
  }

  async function logoutClicked() {
    await logoutUser();
    navigate('/join');
  }

  function renderLogin() {
    if (user._id) {
      return <Button variant='contained' onClick={logoutClicked} sx={{height: '30px'}}>Sign Out</Button>
    } else {
      return <Button variant='contained' onClick={loginClicked} sx={{height: '30px'}}>Sign In</Button>
    }
  }

  return (
    <div className='page-header'>
      <Link className='logo' to="/">Bounty</Link>
      { showLogin && renderLogin() }
    </div>
  );
};

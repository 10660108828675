import { Button } from '@mui/material';
import './Join.scss';
import PageHeader from '../PageHeader';
import PageFooter from '../PageFooter';
import { useUserContext } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';

export default function Join() {
  const navigate = useNavigate();
  const { user, hunter } = useUserContext();

  if (user.unresolved) {
    return null;
  }

  function signUpSection() {
    if (user.role === 1) {
      if (user.active) {
        return (
          <div className='member'>
            <h1>You're Already a Member!</h1>
            <p>It looks like you already have an active membership. Enjoy all your benefits and get the most out of your account today!</p>
            <Button variant='contained' onClick={() => navigate('/')}>
              Explore Now
            </Button>
          </div>
        );
      }
      if (new Date(user.active) < new Date()) {
        return (
          <div className='member'>
            <h1>Welcome back!</h1>
            <p>Your membership has expired.</p>
            <Button variant='contained' onClick={() => navigate('/signup/plan')}>
              Restart Membership
            </Button>
          </div>
        );
      }
      return (
        <div className='member'>
          <h1>Unlimited movies, TV shows, and more</h1>
          <p>Starts at $6.99. Cancel anytime.</p>
          <Button variant='contained' onClick={() => navigate('/signup/plan')}>
            Finish Sign Up
          </Button>
        </div>
      );
    }
    if (user.role === 2) {
      if (hunter.handle) {
        return (
          <div className='member'>
            <h1>You're Already a Content Creator!</h1>
            <p>It looks like you already have an active membership. Start creating content today!</p>
            <Button variant='contained' onClick={() => navigate('/')}>
              Explore Now
            </Button>
          </div>
        );
      }
      return (
        <div className='member'>
          <h1>Welcome Back!</h1>
          <p>You're on your way to becoming a creator.</p>
          <Button variant='contained' onClick={() => navigate('/signup/handle/creator')}>
            Finish Sign Up
          </Button>
        </div>
      );
    }
    return (
      <div className='member'>
        <h1>Unlimited movies, TV shows, and more</h1>
        <p>Starts at $6.99. Cancel anytime.</p>
        <Button variant='contained' onClick={() => navigate('/register/member')}>
          Get Started
        </Button>
      </div>
    );
  }

  return (
    <div className='main-body'>
      <PageHeader showLogin />
        { signUpSection() }
      <PageFooter />
    </div>
  );
};

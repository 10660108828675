import { useEffect, useState } from 'react';
import { Alert, Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Navigate, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../contexts/UserContext';
import { updateHunterStageName } from '../../api';

export default function StageName() {
  const navigate = useNavigate();
  const { user, hunter, setHunter } = useUserContext();
  const [pageError, setPageError] = useState('');
  const [loading, setLoading] = useState(false);
  const [stageName, setStageName] = useState('');

  useEffect(() => {
    setStageName(hunter.stagename ?? '');
  },[hunter]);

  async function applyClicked() {
    try {
      setPageError('');
      setLoading(true);
  
      const result = await updateHunterStageName(stageName || 'null');
      if (result.status !== 200) {
        setPageError('We encountered a problem while setting your stage name (Error: ' + result.status + '). Please try again, or contact support if the issue persists.');
      } else {
        setHunter(result.data);
        navigate('/');
      }
    }
    catch (error) {
      setPageError('A system error occurred. Please try again later, or if the problem continues, reach out to our support team. Error Details: ' + error);
    }
    finally {
      setLoading(false);
    }
  }

  if (!hunter.handle) {
    return <Navigate to="/join" replace />;
  }

  return (
    <div className='form-page'>
      <h3>Step 3 of 3</h3>
      <h1>Your Stage Name</h1>
      <h2>Make Your Mark with a Unique Name</h2>
      <p>Your stage name is how you'll be known and remembered on our platform. While optional, it's a great way to establish your brand and stand out. Think of a name that captures your persona or the essence of your content. This name will be visible alongside your profile handle.</p>
      <TextField
        label='Stage Name'
        helperText="Choose a name that's 5-30 characters long. Be creative!"
        variant='outlined'
        value={stageName}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            applyClicked();
          }
        }}
        onChange={(event) => {
          setStageName(event.target.value.substring(0,30));
        }}
      />
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={applyClicked}>
        <span>{'Confirm Stage Name'}</span>
      </LoadingButton>
      <Button onClick={() => navigate("/")}>Skip for Now</Button>
    </div>
  );
};

import './ClaimCreateDialog.scss';
import { Alert, Button, Dialog, DialogContent, DialogTitle, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Bounty, IFile } from "../../types";
import CloseIcon from '@mui/icons-material/Close';
import ImageViewer from "../ImageViewer";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postClaim } from "../../api";

interface ClaimCreateDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  bounty: Bounty;
  file: IFile;
}

export default function ClaimCreateDialog({ open, setOpen, bounty, file }: ClaimCreateDialogProps) {
  const navigate = useNavigate();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [pageError, setPageError] = useState('');

  async function postClicked() {
    try {
      setPageError('');
      const result = await postClaim(bounty._id, file);
      if (result.status !== 200) {
        setPageError('Error posting claim: ' + result.status);
      } else {
        setOpen(false);
        navigate('/claim/' + result.data._id);
      }
    }
    catch (error) {
      console.error('postClicked', error);
      setPageError('Post error: ' + error);
    }
  }
  
  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={() => {setOpen(false)}}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="dialog-title">Post Claim</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={() => {setOpen(false)}}
        sx={{
          color: (theme) => theme.palette.grey[500],
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <div className="claim-dialog">
          <ImageViewer file={file} />
          {pageError && <Alert severity="error">{pageError}</Alert> }
          <Button variant="contained" fullWidth onClick={postClicked}>Post</Button>
          <Button variant="outlined" fullWidth onClick={() => {setOpen(false)}}>Cancel</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
import './BountyPending.scss';
import { useState } from "react";
import { Bounty } from "../../types";
import { formatMoney } from "../../globals";
import { getBounty } from '../../api';
import { useNavigate } from 'react-router-dom';

interface BountyPendingProps {
  bounty: Bounty;
}

export default function BountyPending({ bounty }: BountyPendingProps) {
  const navigate = useNavigate();
  const [loadedBounty, setLoadedBounty] = useState(bounty);

  function bountyClicked() {
    navigate(`/bounty/${bounty._id}`);
  }

  function handleFund() {
    getBounty(bounty._id).then(result => {
      if (result.status === 200) {
        setLoadedBounty(result.data);
      }
    });
  }

  return (
    <div className='bounty-pending' onClick={bountyClicked}>
      <div className='top'>
        <div className='title'>{loadedBounty.title}</div>
      </div>
      <div className='bottom'>
        <div className='phrase'>Freshly Posted Fantasy - Give It the Boost It Needs!</div>
        <div className='amount'>Bounty: {formatMoney(loadedBounty.silver)}</div>
      </div>
    </div>
  )
}
import { useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { updateSSN } from '../../api';
import { useUserContext } from '../../contexts/UserContext';

interface SsnEditProps {
  next?: string;
}

export default function SsnEdit({ next }: SsnEditProps) {
  const navigate = useNavigate();
  const { setUser } = useUserContext();
  const { snackSuccess } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [socialSecurityNumber, setSocialSecurityNumber] = useState('');
  const [pageError, setPageError] = useState('');

  async function applyClicked() {
    try {
      setPageError('');
      setLoading(true);
  
      if (socialSecurityNumber.length < 9) {
        setPageError("The Social Security Number entered is incomplete. Please ensure you have entered all 9 digits.");
      } else {
        const result = await updateSSN(socialSecurityNumber);
        if (result.status !== 200) {
          setPageError("We encountered an issue while updating your information (Error: " + result.status + "). Please try again or contact support if the problem persists.");
        } else {
          setUser(result.data);
          if (next) {
            navigate(next);
          } else {
            snackSuccess("Success! Your SSN has been updated.");
            navigate('/account');
          }
        }
      }
    }
    catch (error) {
      setPageError("A system error occurred. Please try again later or contact our support team for assistance. Error Details: " + error);
    }
    finally {
      setLoading(false);
    }
  }
  
  return (
  <form noValidate>
    <div className='form-page'>
      <h1>Provide Your Social Security Number</h1>
      <h2>Tax Information Requirement</h2>
      <p>For tax reporting purposes and in compliance with IRS regulations, we require your Social Security Number (SSN). This information is necessary to accurately report your earnings and to ensure that all payments made to you are properly documented for tax purposes.</p>
      <TextField
        label="Social Security Number (SSN)"
        helperText="Enter your 9-digit US Social Security Number. Format: XXX-XX-XXXX."
        variant="outlined"
        value={socialSecurityNumber}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            applyClicked();
          }
        }}
        onChange={event => {
          setSocialSecurityNumber(event.target.value.replace(/[^\d]/g, '').substring(0, 9));
        }}
      />
      <p>Your SSN will be securely stored and used solely for financial and tax reporting purposes as required by law. Please ensure the accuracy of the information provided.</p>
      {pageError && <Alert severity="error">{pageError}</Alert> }
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={applyClicked}>
        <span>{"Submit SSN Details"}</span>
      </LoadingButton>
    </div>
  </form>
  );
};

import { useEffect, useState } from 'react';
import { Alert, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/AppContext';
import { useUserContext } from '../../contexts/UserContext';
import { updateBankInfo } from '../../api';

interface BankEditProps {
  next?: string;
}

export default function BankEdit({ next }: BankEditProps) {
  const navigate = useNavigate();
  const { snackSuccess } = useAppContext();
  const { user, setUser } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [accountType, setAccountType] = useState('checking');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [pageError, setPageError] = useState('');

  useEffect(() => {
    setName(user.bankInfo?.name);
    setAccountType(user.bankInfo?.accountType);
    setRoutingNumber(user.bankInfo?.routingNumber);
  }, [user]);

  async function applyClicked() {
    try {
      setPageError('');

      if (!name) {
        setPageError("Please enter the name of your bank. This field cannot be left blank.");
      } else if (routingNumber.length < 9) {
        setPageError("Invalid routing number. This field is required for processing transactions.");
      } else if (accountNumber.length < 5) {
        setPageError("Invalid bank account number. This field is necessary to complete your direct deposit setup.");
      } else {
        const result = await updateBankInfo({
          name,
          accountType,
          routingNumber,
          accountNumber
        });
        if (result.status !== 200) {
          setPageError("Error updating banking information " + result.status);
        } else {
          setUser(result.data);
          if (next) {
            navigate(next);
          } else {
            snackSuccess("Your banking information has been securely saved.");
            navigate('/account');
          }
        }
      }
      setLoading(true);
    }
    catch (error) {
      setPageError('Error ' + error);
    }
    finally {
      setLoading(false);
    }
  }

  return (
    <form noValidate>
      <div className='form-page'>
        <h1>Direct Deposit Setup</h1>
        <h2>Provide your banking details to enable seamless direct deposits of your earnings.</h2>
        <TextField
          label="Bank name"
          helperText="Enter the full name of your bank."
          variant="outlined"
          value={name}
          onChange={event => {
            setName(event.target.value.substring(0, 50));
          }}
        />
        <TextField
          label="Routing number"
          helperText="Enter your bank's 9 digit routing number."
          variant="outlined"
          value={routingNumber}
          onChange={event => {
            setRoutingNumber(event.target.value.replace(/[^\d]/g, '').substring(0, 9));
          }}
        />
        <div>
          <RadioGroup row value={accountType} onChange={(event) => {
            setAccountType(event.target.value)
          }}>
            <FormControlLabel value="checking" control={<Radio />} label="Checking Account" />
            <FormControlLabel value="savings" control={<Radio />} label="Savings Account" />
          </RadioGroup>
        </div>
        <TextField
          label="Account number"
          helperText="Enter your bank account number."
          variant="outlined"
          value={accountNumber}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              applyClicked();
            }
          }}
          onChange={event => {
            setAccountNumber(event.target.value.replace(/[^\d]/g, '').substring(0, 20));
          }}
        />
        <p>Earnings are disbursed on a weekly basis. Annually, a Form 1099 reflecting your total earnings will be issued and submitted to the Internal Revenue Service (IRS). It is essential for creators to independently track their earnings and report them accurately on their tax returns. This ensures compliance with tax regulations and helps avoid any discrepancies.</p>
        {pageError && <Alert severity="error">{pageError}</Alert> }
        <LoadingButton 
          variant="contained" 
          loading={loading} 
          onClick={applyClicked}>
          <span>{"Submit Details"}</span>
        </LoadingButton>
      </div>
    </form>
  );
};

import axios, { AxiosResponse } from 'axios';
import { Session, Bounty, Claim, User, Vote, Post, ICreditCard, IAddress, IBankInfo, Hunter, UserFull, IFile } from './types';
import { appConfig } from './appConfig';

interface ApiResponse<T> {
  status: number;
  data: T;
}

async function handleApiRequest<T>(request: Promise<AxiosResponse<T>>): Promise<ApiResponse<T>> {
  try {
    const response = await request;
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Axios error: ", error.message);
      return {
        status: error.response?.status || 500,
        data: {} as T,
      };
    } else {
      console.error("Unexpected error: ", error);
      return {
        status: 500,
        data: {} as T,
      };
    }
  }
}

const api = axios.create({
  baseURL: appConfig.apiUrl,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('ss');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export async function authenticate(email: string, password: string): Promise<ApiResponse<Session>> {
  const request = api.post<Session>('/auth', { email, password });
  return await handleApiRequest(request);
}

export async function logout(sessionId: string): Promise<ApiResponse<Session>> {
  const request = api.delete<Session>('/auth/' + sessionId);
  return await handleApiRequest(request);
}

export async function createUser(email: string, password: string, hunter: boolean): Promise<ApiResponse<User>> {
  const request = api.post<User>('/user', { email, password, hunter });
  return await handleApiRequest(request);
}

export async function getUser(): Promise<ApiResponse<UserFull>> {
  const request = api.get<UserFull>('/user');
  return await handleApiRequest(request);
}

export async function updateName(firstName: string, lastName: string): Promise<ApiResponse<User>> {
  const request = api.put<User>('/user/name', { firstName, lastName });
  return await handleApiRequest(request);
}

export async function updateEmail(email: string): Promise<ApiResponse<User>> {
  const request = api.put<User>('/user/email/' + email);
  return await handleApiRequest(request);
}

export async function sendVerifyEmail(): Promise<ApiResponse<void>> {
  const request = api.post<void>('/email/verify');
  return await handleApiRequest(request);
}

export async function verifyEmail(code: string): Promise<ApiResponse<User>> {
  const request = api.put<User>('/user/email/verify/' + code);
  return await handleApiRequest(request);
}

export async function sendReset(email: string): Promise<ApiResponse<void>> {
  const request = api.post<void>('/email/password/' + email);
  return await handleApiRequest(request);
}

export async function resetPassword(resetCode: string, newPassword: string): Promise<ApiResponse<void>> {
  const request = api.put<void>('/user/reset', { resetCode, newPassword });
  return await handleApiRequest(request);
}

export async function updatePassword(oldPassword: string, newPassword: string): Promise<ApiResponse<void>> {
  const request = api.put<void>('/user/password', { oldPassword, newPassword });
  return await handleApiRequest(request);
}

export async function updateUserHandle(handle: string): Promise<ApiResponse<User>> {
  const request = api.put<User>('user/handle/' + handle);
  return await handleApiRequest(request);
}

export async function updatePlan(plan: number): Promise<ApiResponse<User>> {
  const request = api.put<User>('/user/plan/' + plan);
  return await handleApiRequest(request);
}

export async function updateCreditCard(card: ICreditCard): Promise<ApiResponse<User>> {
  const request = api.put<User>('/user/card', card);
  return await handleApiRequest(request);
}

export async function startMembership(card: ICreditCard): Promise<ApiResponse<User>> {
  const request = api.post<User>('/user/membership', card);
  return await handleApiRequest(request);
}

export async function getHunterProfileByHandle(handle: string): Promise<ApiResponse<Hunter>> {
  const request = api.get<Hunter>('/hunter/handle/' + handle);
  return await handleApiRequest(request);
}

export async function updateHunterHandle(handle: string): Promise<ApiResponse<Hunter>> {
  const request = api.put<Hunter>('hunter/handle/' + handle);
  return await handleApiRequest(request);
}

export async function updateHunterBio(bio: string): Promise<ApiResponse<Hunter>> {
  const request = api.put<Hunter>('/hunter/bio/' + bio);
  return await handleApiRequest(request);
}

export async function updateHunterStageName(stagename: string): Promise<ApiResponse<Hunter>> {
  const request = api.put<Hunter>('/hunter/stagename/' + stagename);
  return await handleApiRequest(request);
}

export async function updateHunterProfilePicture(file: IFile): Promise<ApiResponse<Hunter>> {
  const request = api.put<Hunter>('/hunter/picture', file);
  return await handleApiRequest(request);
}

export async function updateHunterBanner(file: IFile): Promise<ApiResponse<Hunter>> {
  const request = api.put<Hunter>('/hunter/banner', file);
  return await handleApiRequest(request);
}

export async function updateAddress(address: IAddress): Promise<ApiResponse<User>> {
  const request = api.put<User>('/user/address', address);
  return await handleApiRequest(request);
}

export async function updateIdentity(idFront: IFile, idSelfie: IFile): Promise<ApiResponse<User>> {
  const request = api.put<User>('/user/identity', { idFront, idSelfie });
  return await handleApiRequest(request);
}

export async function updateBankInfo(bankInfo: IBankInfo): Promise<ApiResponse<User>> {
  const request = api.put<User>('/user/bank', bankInfo);
  return await handleApiRequest(request);
}

export async function updateSSN(ssn: string): Promise<ApiResponse<User>> {
  const request = api.put<User>('/user/ssn/' + ssn);
  return await handleApiRequest(request);
}

export async function postBounty(title: string, seconds: number): Promise<ApiResponse<Bounty>> {
  const request = api.post<Bounty>('/bounty', { title, seconds });
  return await handleApiRequest(request);
}

export async function fundBounty(bountyId: string, silver: number, gold: number): Promise<ApiResponse<User>> {
  const request = api.post<User>('/bounty/fund', { bountyId, silver, gold });
  return await handleApiRequest(request);
}

export async function bountySearch(term: string, excludeId?: string): Promise<ApiResponse<Bounty[]>> {
  const request = api.post<Bounty[]>('/bounty/search', { term, excludeId, sfw: appConfig.sfw });
  return await handleApiRequest(request);
}

export async function getBounty(bountyId: string): Promise<ApiResponse<Bounty>> {
  const request = api.get<Bounty>('/bounty/' + bountyId);
  return await handleApiRequest(request);
}

export async function postClaim(bountyId: string, file: IFile): Promise<ApiResponse<Claim>> {
  const request = api.post<Claim>('/claim', { bountyId, file });
  return await handleApiRequest(request);
}

export async function getBountyClaims(bountyId: string): Promise<ApiResponse<Claim[]>> {
  const request = api.get<Claim[]>('/bounty/claims/' + bountyId);
  return await handleApiRequest(request);
}

export async function getUserClaims(userId: string): Promise<ApiResponse<Claim[]>> {
  const request = api.get<Claim[]>('/user/claims/' + userId);
  return await handleApiRequest(request);
}

export async function getClaimRank(bountyId: string, claimId: string): Promise<ApiResponse<number>> {
  const request = api.get<number>('/bounty/' + bountyId + '/claim-rank/' + claimId);
  return await handleApiRequest(request);
}

export async function getClaim(claimId: string): Promise<ApiResponse<Claim>> {
  const request = api.get<Claim>('/claim/' + claimId);
  return await handleApiRequest(request);
}

export async function postVote(claimId: string, bountyId: string, silver: number): Promise<ApiResponse<Vote>> {
  const request = api.post<Vote>('/vote', { claimId, bountyId, silver });
  return await handleApiRequest(request);
}

export async function getVote(claimId: string): Promise<ApiResponse<Vote>> {
  const request = api.get<Vote>('/vote/' + claimId);
  return await handleApiRequest(request);
}

export async function postPost(text: string, fileName: string): Promise<ApiResponse<Post>> {
  const request = api.post<Post>('/post', { text, fileName });
  return await handleApiRequest(request);
}

export async function getPosts(userId: string): Promise<ApiResponse<Post[]>> {
  const request = api.get<Post[]>('/posts/' + userId);
  return await handleApiRequest(request);
}

export async function uploadImage(file: File): Promise<ApiResponse<IFile>> {
  const request = api.post<IFile>('/upload/image', file, {
    headers: {
      'Content-Type': file.type
    }
  });
  return await handleApiRequest(request);
}
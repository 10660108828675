import './HunterPost.scss';
import { useState } from 'react';
import { Button, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import MediaUploader from './MediaUploader';
import { postPost } from '../api';
import { useAppContext } from '../contexts/AppContext';

export default function HunterPost() {
  const navigate = useNavigate();
  const { snackError, snackClose } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [file, setFile] = useState('');

  async function postClicked() {
    try {
      snackClose();
      const postResult = await postPost(text, file);
      if (postResult.status !== 200) {
        snackError('Error updating profile code ' + postResult.status);
      } else {
        navigate('/profile');
      }
    }
    catch (error) {
      snackError("Error " + error);
    }
    finally {
      setLoading(false);
    }
  }

  async function onHandleUpload(status: number, fileName: string) {
    if (status !== 200) {
      console.log("ERROR");
    } else {
      setFile(file);
    }
  }

  return (
    <div className='form-page'>
      <h1>Create Post</h1>
      <h2>Select a Photo, Video, or GIF to Share</h2>
      {file && <img className='post-picture' src={'https://videos.bounty.fan/' + file } alt='post' />}
      <MediaUploader onUpload={onHandleUpload}>Upload Media</MediaUploader>
      <h2>Detail Your Post</h2>
      <TextField
        fullWidth
        label='Body'
        helperText='Bring your post to life with words. A little context goes a long way in helping others appreciate your content more fully.'
        multiline
        rows={4}
        variant='outlined'
        value={text}
        onChange={(event) => {
          snackClose();
          setText(event.target.value.substring(0,200));
        }}
      />
      <LoadingButton 
        variant="contained" 
        loading={loading} 
        onClick={postClicked}>
        <span>Post</span>
      </LoadingButton>
      <Button onClick={() => { navigate('/profile')}}>Cancel</Button>
    </div>
  );
};

import { ChangeEvent, useState } from 'react';
import './SearchDialog.scss';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";

interface SearchDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function SearchDialog({ open, setOpen }: SearchDialogProps) {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  function handleSearchChange(event: ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    setSearchTerm(value);
  };

  function searchClicked() {
    navigate('/?q=' + searchTerm);
    setOpen(false);
  }
  
  return (
    <div className='search-dialog'>
      <div className='search-bar'>
        <FontAwesomeIcon className='search-icon' icon={faSearch} />
        <input
          className="search-input"
          type="text"
          placeholder="Search..."
          autoFocus
          value={searchTerm}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              searchClicked();
            }
          }}
          onChange={handleSearchChange}
        />
      </div>
    </div>
  );
}
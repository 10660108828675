import { useNavigate } from "react-router-dom";
import "./Setting.scss";

interface SettingProp {
  title: string;
  path?: string;
  onClick?: () => void;
  children?: React.ReactNode;
}

export default function Setting({ title, path, onClick, children }: SettingProp) {
  const navigate = useNavigate();

  function settingClicked() {
    if (path) {
      navigate(path);
    } else if (onClick) {
      onClick();
    }
  }

  return (
    <p className="setting" onClick={settingClicked}>
      <span className="title">{title}</span>
      {children}
    </p>
  );
}

import * as React from 'react';
import { PutObjectCommand, PutObjectCommandInput, S3Client } from '@aws-sdk/client-s3';
import { LoadingButton } from '@mui/lab';
import { v4 as uuidv4 } from 'uuid';

interface MediaUploaderProps {
  onUpload: (status: number, file: string) => void;
  children: React.ReactNode; 
}

const s3Client = new S3Client({
  forcePathStyle: false,
  endpoint: "https://sfo2.digitaloceanspaces.com",
  region: "us-east-1",
  credentials: {
    accessKeyId: "DO00C62KPPGWDEZFYFLX",
    secretAccessKey: "CcewTva2zJ2c9VBHdm263IgXsSjCGL/OswAGHd+DyrI"
  }
});

export default function MediaUploader({ onUpload, children }: MediaUploaderProps) {
  const [loading, setLoading] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true);
      const file = event.target.files?.[0];
      if (file) {
        const fileExtension = file.name.split('.').pop();
        if (fileExtension) {
          const fileName = uuidv4() + '.' + fileExtension;
          const params: PutObjectCommandInput = {
            Bucket: "bounty",
            Key: fileName,
            Body: file,
            ACL: "public-read",
          };
    
          const data = await s3Client.send(new PutObjectCommand(params));
          onUpload(200, fileName);
          console.log(
            "Successfully uploaded object: " +
              params.Bucket +
              "/" +
              params.Key
          );
        }
      }
    } catch (ex) {
      console.log("Error", ex);
    }
    finally {
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    inputRef.current?.click();
  };


  return (
    <LoadingButton
      variant="contained" 
      loading={loading}
      onClick={handleButtonClick}
    >
      { loading ? 'Uploading' : children }
      <input
        ref={inputRef}
        accept="image/*,video/*"
        multiple={false}
        type="file"
        hidden
        onChange={handleFileInputChange}
      />
    </LoadingButton>
  );
}

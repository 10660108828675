import { useParams } from 'react-router-dom';
import './ClaimPage.scss';
import { useEffect, useState } from 'react';
import { Claim } from '../../types';
import { getClaim } from '../../api';
import BountyBar from './BountyBar';
import { useAppContext } from '../../contexts/AppContext';
import ClaimBar from './ClaimBar';
import BountyFeed from './BountyFeed';
import HunterBar from './HunterBar';
import MediaViewer from '../MediaViewer';
import ScrollToTop from '../ScrollToTop';
import { bountyActive } from '../../globals';
import ClaimActiveBar from './ClaimActiveBar';

export default function ClaimPage() {
  const { id } = useParams();
  const { snackError } = useAppContext();
  const [loadCount, setLoadCount] = useState(0);
  const [claim, setClaim] = useState({ } as Claim);

  useEffect(() => {
    setLoadCount(value => value + 1);
  }, [id]);

  useEffect(() => {
    if (id && loadCount) {
      getClaim(id).then(result => {
        if (result.status !== 200) {
          snackError("Error loading claim: " + result.status);
          console.error('getClaim: %d', result.status);
        } else {
          setClaim(result.data);
        }
      });
    }
  }, [loadCount]);
  
  return (
    <div className='claim-page'>
      <ScrollToTop />
      <div className='claim-box'>
        <BountyBar bounty={claim.bounty} />
        <MediaViewer file={claim.file} />
        <ClaimBar claim={claim} />
        {
          bountyActive(claim.bounty) === 2 && 
          <ClaimActiveBar claim={claim} onVote={() => setLoadCount(count => count + 1)} />
        }
      </div>
      <HunterBar hunter={claim.hunter} />
      { claim.bountyId && <BountyFeed search={claim.bounty.title} claimId={claim._id} excludeId={claim.bountyId} /> }
    </div>
  );
}
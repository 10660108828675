import './HunterPage.scss';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { getPosts, getHunterProfileByHandle, getUserClaims } from '../api';
import { useNavigate, useParams } from 'react-router-dom';
import { Claim, Hunter, Post } from '../types';
import { formatPostDate } from '../globals';
import BountyBar from './bounties/BountyBar';
import ClaimBar from './bounties/ClaimBar';
import HunterBar from './bounties/HunterBar';
import PageLoading from './PageLoading';
import { useUserContext } from '../contexts/UserContext';
import MediaViewer from './MediaViewer';

export default function HunterPage() {
  const navigate = useNavigate();
  const { handle } = useParams();
  const { hunter } = useUserContext();
  const [profile, setProfile] = useState({ unresolved: true } as Hunter);
  const [claims, setClaims] = useState([] as Claim[]);
  const [posts, setPosts] = useState([] as Post[]);
  const [feed, setFeed] = useState([] as (Post | Claim)[]);

  useEffect(() => {
    if (handle) {
      getHunterProfileByHandle(handle).then(result => {
        if (result.status === 200) {
          setProfile(result.data);
        } else {
          setProfile({} as Hunter);
        }
      });
    } else {
      setProfile(hunter);
    }
  }, [handle, hunter]);

  useEffect(() => {
    if (profile._id) {
      getPosts(profile._id).then(result => {
        setPosts(result.status === 200 ? result.data : []);
      });
      getUserClaims(profile._id).then(result => {
        setClaims(result.status === 200 ? result.data : []);
      });
    }
  },[profile]);

  useEffect(() => {
    const combinedArray = [...posts, ...claims];
    combinedArray.sort((a, b) => {
      return new Date(b.created).getTime() - new Date(a.created).getTime();
    });
    setFeed(combinedArray);
  }, [posts, claims]);

  function renderPost(post: Post) {
    return (
      <div key={post._id} className='content-box'>
        <MediaViewer file={post.file} />
        <div className='content-row'>
          {post.text}
        </div>
        <div className='content-row'>
          {formatPostDate(post.created)}
        </div>
      </div>
    );
  }

  function renderClaim(claim: Claim) {
    return (
      <div key={claim._id} className='content-box'>
        <MediaViewer file={claim.file} />
        <BountyBar bounty={claim.bounty} />
        <ClaimBar  claim={claim} />
      </div>
    );
  }

  if (profile?.unresolved) return <PageLoading />

  return (
    <div className='content-page'>
      <HunterBar hunter={profile} />
      { (profile._id && profile._id === profile._id) && <div className='content-row'>
        <Button
          variant="contained"
          fullWidth
          onClick={() => navigate('/profile/edit')}
        >
          <FontAwesomeIcon className='button-icon' icon={faPenToSquare} />  
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={() => navigate('/profile/post')}
        >
          <FontAwesomeIcon className='button-icon' icon={faAdd} />  
        </Button>
      </div>}
      {
        feed.map(item => { return 'bountyId' in item ? renderClaim(item) : renderPost(item)})
      }
    </div>
  );
};

import { useEffect, useState } from 'react';
import { Alert, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { updateAddress } from '../../api';
import { useAppContext } from '../../contexts/AppContext';
import { useUserContext } from '../../contexts/UserContext';

interface AddressEditProps {
  next?: string;
}

export default function AddressEdit({ next }: AddressEditProps) {
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const { snackSuccess } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [pageError, setPageError] = useState('');

  useEffect(() => {
    setStreet(user.taxAddress?.street);
    setCity(user.taxAddress?.city);
    setState(user.taxAddress?.state);
    setZip(user.taxAddress?.zip);
  }, [user]);

  async function applyClicked() {
    try {
      setPageError('');
      setLoading(true);
  
      if (!street.trim()) {
        setPageError("Address is required. Please enter your street address.");
      } else if (!city.trim()) {
        setPageError("City is required. Please enter the name of your city.");
      } else if (!state.trim()) {
        setPageError("State is required. Please enter the name of your state or province.");
      } else if (!zip.trim()) {
        setPageError("Postal/Zip code is required. Please enter your area's postal or zip code.");
      } else {
        const result = await updateAddress({ street, city, state, zip });
        if (result.status !== 200) {
          setPageError("There was an issue updating your address information (Error: " + result.status + "). Please ensure all details are accurate and retry, or contact our support team for help.");
        } else {
          setUser(result.data);
          if (next) {
            navigate(next);
          } else {
            snackSuccess("Your address has been successfully updated for tax documentation purposes. Thank you for keeping your information current.");
            navigate('/account');
          }
        }
      }
    }
    catch (error) {
      setPageError("An unexpected error occurred. Please try submitting again later. If the problem continues, please reach out to our support team. Error Details: " + error);
    }
    finally {
      setLoading(false);
    }
  }
  
  return (
  <div className='form-page'>
    <h1>Address Verification for Tax Compliance</h1>
    <h2>Enter Your Address Details</h2>
    <p>
      In order to comply with tax regulations, we require your complete and current residential address. This information is necessary for official tax documentation and will be used solely for this purpose.
    </p>
    <TextField
      label="Street Address"
      variant="outlined"
      value={street}
      helperText="Enter the street address of your residence."
      onChange={event => {
        setStreet(event.target.value.substring(0, 50));
      }}
    />
    <TextField
      label="City"
      variant="outlined"
      value={city}
      helperText="Enter the city of your residence."
      onChange={event => {
        setCity(event.target.value.substring(0, 50));
      }}
    />
    <TextField
      label="State"
      variant="outlined"
      value={state}
      helperText="Enter the state or province of your residence."
      onChange={event => {
        setState(event.target.value.substring(0, 20));
      }}
    />
    <TextField
      label="Postal / Zip Code"
      variant="outlined"
      value={zip}
      helperText="Enter your area's postal or zip code."
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          applyClicked();
        }
      }}
      onChange={event => {
        setZip(event.target.value.substring(0, 20));
      }}
    />
    {pageError && <Alert severity="error">{pageError}</Alert> }
    <LoadingButton 
      variant="contained" 
      loading={loading} 
      onClick={applyClicked}>
      <span>{"Submit Address Details"}</span>
    </LoadingButton>
  </div>
  );
};

import './HunterBar.scss';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { Hunter } from '../../types';

interface HunterBarProps {
  hunter: Hunter;
}

export default function HunterBar({ hunter }: HunterBarProps) {
  const navigate = useNavigate();

  function handleClick() {
    navigate('/profile/' + hunter.handle);
  }

  if (!hunter) return <div />
  return (
    <div className='hunter-bar' onClick={handleClick} style={{backgroundImage: 'url(https://videos.bounty.fan/' + (hunter.banner ?? 'blank.jpeg')  + ')'}}>
      <div className='picture' style={{backgroundImage: 'url(https://videos.bounty.fan/' + (hunter.picture ?? 'blank.jpeg') + ')'}} />
      <div className='stack'>
        <div className='handle'>{hunter.handle}</div>
        <div className='info-row'>
          <span><FontAwesomeIcon icon={faUser} />3.4 K</span>
          <span><FontAwesomeIcon icon={faImages} />134</span>
        </div>
      </div>
    </div>
  );
}